<template>
  <div v-if="!$store.state.pcshow"></div>
  <div class="bussinessCntainer" v-else>
    <div><img src="../assets/img/corporateNews/newsBg.jpg" class="topBg" /></div>
    <div class="newsCon">
      <div class="title">我国医疗器械监管法规体系的构建、政策及发展建议</div>
      <div class="times">2021-06-09</div>
      <div class="con">
        <div>医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科,高分子材料等多个专业，其复杂性、多样性对监管</div>
        <div>学性提出了越来越高的要求。当前我国医疗健康产业发展迅速，公众对...</div>
        <div style="margin-top:60px;">医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科,高分子材料等多个专业，其复杂性、多样性对监管</div>
        <div style="margin-top:60px;">医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科,高分子材料等多个专业，其复杂性、多样性对监管</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
       
        
      }
    },
    created() {
     
    },
    methods: {

    }
  }
  

</script>

<style scoped>
  @media screen and (max-width: 960px){

  }

  @media screen and (min-width: 960px){
  .bussinessCntainer{
    width:100%;
    padding-top:90px;
  }
  .topBg{
    width:100%;
    height: 300px;
   overflow:hidden;
  }
 .newsCon{
    width: 1220px;
    background: #FFFFFF;
    margin:39px auto  133px auto;
  }
    .title{
        font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 600;
color: #333333;
    }
    .times{
        font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #999999;
margin-top:30px;
margin-bottom:30px;
    }
    .con{
        font-size: 14px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #333333;
line-height: 1.5;
border-top:1px solid #EAEAEA;
padding-top:30px;
    }
  }
</style>
